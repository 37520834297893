var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"expoiltationmensuelle"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS D'EXPLOITATION DU MOIS")])]),_c('hr'),_c('b-card-body',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"table-responsive text-center mt-1"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Produits ")]),_c('th',[_vm._v("Chiffre d'affaires")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Vente de chambres ou unités d'hébergement ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.chiffreAffaireDetails.venteChambre
                            ),callback:function ($$v) {_vm.$set(_vm.infos.chiffreAffaireDetails, "venteChambre", _vm._n($$v))},expression:"\n                              infos.chiffreAffaireDetails.venteChambre\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Restauration et consommation de boissons ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.chiffreAffaireDetails.restauConsommation
                            ),callback:function ($$v) {_vm.$set(_vm.infos.chiffreAffaireDetails, "restauConsommation", _vm._n($$v))},expression:"\n                              infos.chiffreAffaireDetails.restauConsommation\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Location salles de conférences ou réunions ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.chiffreAffaireDetails.locationSalle
                            ),callback:function ($$v) {_vm.$set(_vm.infos.chiffreAffaireDetails, "locationSalle", _vm._n($$v))},expression:"\n                              infos.chiffreAffaireDetails.locationSalle\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Autres ")]),_c('td',[_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                            _vm.infos.chiffreAffaireDetails.autres
                          ),callback:function ($$v) {_vm.$set(_vm.infos.chiffreAffaireDetails, "autres", _vm._n($$v))},expression:"\n                            infos.chiffreAffaireDetails.autres\n                          "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Total ")]),_c('td',[_vm._v(" "+_vm._s(_vm.infos.chiffreAffaireDetails.venteChambre + _vm.infos.chiffreAffaireDetails.restauConsommation + _vm.infos.chiffreAffaireDetails.locationSalle + _vm.infos.chiffreAffaireDetails.autres)+" ")])])])])])]),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Charges salariales (FCFA)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez sasir le montant des charges salariales (FCFA)"},model:{value:(_vm.infos.chargesSalariales),callback:function ($$v) {_vm.$set(_vm.infos, "chargesSalariales", _vm._n($$v))},expression:"infos.chargesSalariales"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Autres Dépenses (FCFA) *"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez sasir le montant des autres dépenses"},model:{value:(_vm.infos.autresDepense),callback:function ($$v) {_vm.$set(_vm.infos, "autresDepense", _vm._n($$v))},expression:"infos.autresDepense"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Nombre d'arrivées *"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez sasir le nombre d'arrivées"},model:{value:(_vm.infos.nombreArrives),callback:function ($$v) {_vm.$set(_vm.infos, "nombreArrives", _vm._n($$v))},expression:"infos.nombreArrives"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Nombre de nuitées *"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez sasir le nombre de nuitées"},model:{value:(_vm.infos.nombreNuites),callback:function ($$v) {_vm.$set(_vm.infos, "nombreNuites", _vm._n($$v))},expression:"infos.nombreNuites"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Taux d’occupation brut par chambre ou unité d’hébergement (%)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez sasir le taux d’occupation brut par chambre ou unité d’hébergement"},model:{value:(_vm.infos.tauxOccuptationMensuelChambresParChambre),callback:function ($$v) {_vm.$set(_vm.infos, "tauxOccuptationMensuelChambresParChambre", _vm._n($$v))},expression:"infos.tauxOccuptationMensuelChambresParChambre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Taux d’occupation brut par place lit (%)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":"Veuillez sasir le taux d’occupation brut par place lit"},model:{value:(_vm.infos.tauxOccuptationMensuelChambresParLit),callback:function ($$v) {_vm.$set(_vm.infos, "tauxOccuptationMensuelChambresParLit", _vm._n($$v))},expression:"infos.tauxOccuptationMensuelChambresParLit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }