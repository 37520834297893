<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- RENSEIGNEMENTS EXPLOITATION DU MOIS  -->
        <div>
          <b-card id="expoiltationmensuelle">
            <b-card-header class="d-flex justify-content-between">
              <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
            </b-card-header>
            <hr>
            <b-card-body>
              <!-- table -->
              <b-col md="12">
                <div class="table-responsive text-center mt-1">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          Produits
                        </th>
                        <th>Chiffre d'affaires</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Vente de chambres ou unités d'hébergement
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.chiffreAffaireDetails.venteChambre
                              "
                              type="number"
                              v-digits-only="{length: 16}"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Restauration et consommation de boissons
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.chiffreAffaireDetails.restauConsommation
                              "
                              type="number"
                              v-digits-only="{length: 16}"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Location salles de conférences ou réunions
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.chiffreAffaireDetails.locationSalle
                              "
                              type="number"
                              v-digits-only="{length: 16}"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Autres
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                              infos.chiffreAffaireDetails.autres
                            "
                            type="number"
                            v-digits-only="{length: 16}"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Total
                        </th>
                        <td>
                          {{
                            infos.chiffreAffaireDetails.venteChambre +
                            infos.chiffreAffaireDetails.restauConsommation +
                            infos.chiffreAffaireDetails.locationSalle +
                            infos.chiffreAffaireDetails.autres
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <!-- Charges salariales  -->
              <b-form-group
                label-cols-lg="3"
                label="Charges salariales (FCFA)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model.number="infos.chargesSalariales"
                    v-digits-only="{length: 16}"
                    type="number"
                    min="0"
                    placeholder="Veuillez sasir le montant des charges salariales (FCFA)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Autres Dépenses  -->
              <b-form-group
                label-cols-lg="3"
                label="Autres Dépenses (FCFA) *"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model.number="infos.autresDepense"
                    v-digits-only="{length: 16}"
                    type="number"
                    min="0"
                    placeholder="Veuillez sasir le montant des autres dépenses"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Nombre d'arrivées  -->
              <b-form-group
                label-cols-lg="3"
                label="Nombre d'arrivées *"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model.number="infos.nombreArrives"
                    type="number"
                    v-digits-only="{length: 16}"
                    min="0"
                    placeholder="Veuillez sasir le nombre d'arrivées"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Nombre de nuités  -->
              <b-form-group
                label-cols-lg="3"
                label="Nombre de nuitées *"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model.number="infos.nombreNuites"
                    type="number"
                    v-digits-only="{length: 16}"
                    min="0"
                    placeholder="Veuillez sasir le nombre de nuitées"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Taux d'occupations mensuel chambre*  -->
              <b-form-group
                label-cols-lg="3"
                label="Taux d’occupation brut par chambre ou unité d’hébergement (%)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model.number="infos.tauxOccuptationMensuelChambresParChambre"
                    type="number"
                    v-digits-only="{length: 16}"
                    min="0"
                    placeholder="Veuillez sasir le taux d’occupation brut par chambre ou unité d’hébergement"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-cols-lg="3"
                label="Taux d’occupation brut par place lit (%)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model.number="infos.tauxOccuptationMensuelChambresParLit"
                    type="number"
                    v-digits-only="{length: 16}"
                    min="0"
                    placeholder="Veuillez sasir le taux d’occupation brut par place lit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: '2019' },
        { name: '2020' },
        { name: '2021' },
        { name: '2022' },
        { name: '2023' },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      types: [
        { text: 'Agence de voyage', value: 'Agence de voyage' },
        { text: 'Tour Operator', value: 'Tour Operator' },
        { text: 'Agence réceptive', value: 'Agence réceptive' },
      ],
      agrements: [
        { text: 'Non', value: 'Non' },
        { text: 'Oui', value: 'Oui' },
      ],

      infos: {
        chiffreAffaireDetails: {
          venteChambre: 0,
          restauConsommation: 0,
          locationSalle: 0,
          autres: 0,
        },

        // renseignement exploitation
        chargesSalariales: null,
        autresDepense: null,
        nombreArrives: null,
        nombreNuites: null,
        tauxOccuptationMensuelChambresParChambre: null,
        tauxOccuptationMensuelChambresParLit: null,
      },
    }
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
      listMois: 'listMois',
      listAnnees: 'listAnnees',
    }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.loadQuartier();
    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.infos) {
            if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
            if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
            if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
            if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
            if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
            if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

            if (this.infos.telephone2 == '') delete this.infos.telephone2
            if (this.infos.telephone3 == '') delete this.infos.telephone3
            if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
