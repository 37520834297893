<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- RENSEIGNEMENTS GENERAUX  -->
        <div>
          <b-card id="renseignementsgeneraux">
            <b-card-header class="d-flex justify-content-between">
              <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
            </b-card-header>
            <hr>
            <b-card-body>
              <b-row>
                <!-- Nom de l'établissement -->
                <b-col md="6">
                  <b-form-group
                    label="Nom Etablissement"
                  >
                    <b-form-input
                      v-model="infos.nomEtablissement"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <!-- Nom de l'établissement -->
                <b-col md="6">
                  <b-form-group
                    label="Type d’établissement"
                  >
                    <b-form-input
                      v-model="get_sous_type"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <!-- Partie gauche -->
                <b-col md="6">
                  <!-- Nom du promoteur -->
                  <b-form-group
                    label-cols-md="4"
                    label="Nom Promoteur"
                  >
                    <b-form-input
                      v-model="infos.nomPromoteur"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                  <!-- Nationalité du promoteur -->
                  <b-form-group
                    label-cols-lg="4"
                    label="Nationalité Promoteur"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <v-select
                        v-model="infos.nationalitePromoteur"
                        type="text"
                        :options="pays"
                        label="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Partie droite -->
                <b-col md="6">
                  <!-- Prenoms du promoteur -->
                  <b-form-group
                    label-cols-md="4"
                    label="Prénom(s) Promoteur"
                  >
                    <b-form-input
                      v-model="infos.prenomsPromoteur"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- adresse complète -->
              <b-form-group
                class="mt-2"
                label-cols-lg="3"
                label="Adresse Complète Etablissement "
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model="infos.adresseComplete"
                    type="text"
                    placeholder=""
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- telephone -->
              <b-row class="mt-2">
                <b-col md="12">
                  <div>Numéros de Téléphone</div>
                </b-col>
                <b-col
                  class="mt-1"
                  md="4"
                >
                  <b-form-group
                    label-cols-md="3"
                    label="Numéro 1"
                  >
                    <b-form-input
                      v-model="infos.telephone1"
                      v-phonenumber-field
                      type="text"
                      placeholder=""
                    />
                    <!-- <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </b-col>
                <b-col
                  class="mt-1"
                  md="4"
                >
                  <b-form-group
                    label-cols-md="3"
                    label="Numéro 2"
                  >
                    <b-form-input
                      v-model="infos.telephone2"
                      v-phonenumber-field
                      type="text"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  class="mt-1"
                  md="4"
                >
                  <b-form-group
                    label-cols-md="3"
                    label="Numéro 3"
                  >
                    <b-form-input
                      v-model="infos.telephone3"
                      v-phonenumber-field
                      type="text"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- type établissement -->
              <b-row class="mt-2">
                <b-col md="2">
                  Type Etablissement
                </b-col>
                {{ infos.typeEtablissement }}
                <!-- <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="infos.typeEtablissement"
                    :options="typesEts"
                    class="demo-inline-spacing mb-1 ml-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    disabled
                  />
                  <small class="text-danger ml-1">{{ errors[0] }}</small>
                </validation-provider> -->

                <b-col
                  v-if="infos.typeEtablissement == 'Autres'"
                  class=""
                  md="12"
                >
                  <b-form-group
                    label-cols="5"
                    label-cols-lg="3"
                    label="Type Etablissement"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="infos.typeEtablissementAutres"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <!-- agrément -->
              <b-row class="mt-2">
                <b-col md="12">
                  <div>Détenez-vous un agrément du Ministère en charge du transport ?</div></b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="infos.agrementMinistere"
                    :options="agrements"
                    class="demo-inline-spacing mb-1 ml-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                  />
                  <small class="text-danger ml-1">{{ errors[0] }}</small>
                </validation-provider>
                <b-col
                  v-if="infos.agrementMinistere == 'oui'"
                  class="mt-1"
                  md="10"
                >
                  <b-form-group
                    label-cols="5"
                    label-cols-lg="3"
                    label="Référence Agrément"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="infos.referenceAgrement"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-else
                  class="mt-1"
                  md="6"
                />
              </b-row>
              <!-- classement -->
              <b-row class="mt-2">
                <b-col md="12">
                  <div>Classement de l'établissement de l'hébergement</div></b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="infos.classement"
                    :options="classementEtoile"
                    class="demo-inline-spacing mb-1 ml-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                  />
                  <small class="text-danger ml-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-row>
              <!-- réservé à l'admin -->
              <!-- <b-form-group
                class="mt-2"
                label-cols-lg="3"
                label="Réservé à l'administration "
              >
                <b-form-input
                  v-model="infos.resereAdministration"
                  type="text"
                />
              </b-form-group> -->
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import utilsService from '@/services/utils/utils.service'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: '2019' },
        { name: '2020' },
        { name: '2021' },
        { name: '2022' },
        { name: '2023' },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      typesEts: [
        { text: 'Restaurant/Maquis', value: 'Restaurant/Maquis' },
        { text: 'Cafétaria', value: 'Cafétaria' },
        { text: 'Fast Food/Pizzeria', value: 'Fast Food/Pizzeria' },
        { text: 'Bars', value: 'Bars' },
        { text: 'Discothèques', value: 'Discothèques' },
        { text: 'Autres', value: 'Autres' },
      ],
      agrements: [
        { text: 'Non', value: 'non' },
        { text: 'Oui', value: 'oui' },
      ],
      agrementOptions: ['non', 'oui'],
      classementEtoile: [
        { text: 'Non classé', value: 'Non classé' },
        { text: '1 étoile', value: '1 étoile' },
        { text: '2 étoiles', value: '2 étoiles' },
        { text: '3 étoiles', value: '3 étoiles' },
        { text: '4 étoiles', value: '4 étoiles' },
        { text: '5 étoiles', value: '5 étoiles' },
      ],

      infos: {
        nomEtablissement: '',
        nomPromoteur: '',
        prenomsPromoteur: '',
        nationalitePromoteur: '',
        adresseComplete: '',
        telephone1: '',
        telephone2: '',
        telephone3: '',
        typeEtablissement: '',
        agrementMinistere: '',
        referenceAgrement: '',
        classement: '',
      },
    }
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
      listMois: 'listMois',
      listAnnees: 'listAnnees',
    }),
    get_sous_type(){
      const { currentUserId, etablissementDetails } = utilsService.currentUserUtils()
      const { sousTypeEtablissement } = etablissementDetails
      return sousTypeEtablissement
    }
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.annees = utilsService.getListYears()
    this.mois = utilsService.getListMonths()
    this.pays = utilsService.getListCountries()

    // this.loadQuartier();
    if (this.inputData) {
      console.log('🩸')
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }


  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.infos) {
            if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
            if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
            if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
            if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
            if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
            if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

            if (this.infos.telephone2 == '') delete this.infos.telephone2
            if (this.infos.telephone3 == '') delete this.infos.telephone3
            if (this.infos.agrementMinistere == 'non') this.infos.referenceAgrement = ''
          }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
