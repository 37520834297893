var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"capaciteaccueil"}},[_c('b-card-header',[_c('h3',[_vm._v("CAPACITÉ D'ACCEUIL")])]),_c('hr'),_c('b-card-body',[_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Nombre total de chambres ou unités d'hébergements :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":""},model:{value:(_vm.infos.capacitesAccueil.nombreChambres),callback:function ($$v) {_vm.$set(_vm.infos.capacitesAccueil, "nombreChambres", _vm._n($$v))},expression:"infos.capacitesAccueil.nombreChambres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Nombre de chambres ou unités d'hébergement disponibles:"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":""},model:{value:(_vm.infos.capacitesAccueil.nombreChambresDisponibles),callback:function ($$v) {_vm.$set(_vm.infos.capacitesAccueil, "nombreChambresDisponibles", _vm._n($$v))},expression:"infos.capacitesAccueil.nombreChambresDisponibles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Nombre total de places - lits:"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":""},model:{value:(_vm.infos.capacitesAccueil.nombreLits),callback:function ($$v) {_vm.$set(_vm.infos.capacitesAccueil, "nombreLits", _vm._n($$v))},expression:"infos.capacitesAccueil.nombreLits"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-lg":"3","label":"Nombre total de places - lits disponibles:"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":""},model:{value:(_vm.infos.capacitesAccueil.nombreLitsDisponible),callback:function ($$v) {_vm.$set(_vm.infos.capacitesAccueil, "nombreLitsDisponible", _vm._n($$v))},expression:"infos.capacitesAccueil.nombreLitsDisponible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }